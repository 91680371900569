import React, { ErrorInfo, ReactNode } from "react";
import "./style.css";
interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // log the error to an error reporting service
    console.log({ error, errorInfo });

    // Set display: none for the iframe
    this.hideIframe();
  }

  hideIframe() {
    // Find the iframe element
    var iframe = document.getElementById("webpack-dev-server-client-overlay");

    // Update style if the iframe is found
    if (iframe) {
      iframe.style.display = "none";
    }
  }

  render() {
    if (
      this.state.hasError &&
      process.env.REACT_APP_ENVIRONMRNT == "production"
    ) {
      // Hide the iframe when an error occurs
      this.hideIframe();

      return <h1>Oops, something went wrong.</h1>;
    }

    return this.props.children;
  }
}

// Set up window.onerror to log uncaught errors
window.onerror = function (message, source, lineno, colno, error) {
  console.error({ message, source, lineno, colno, error });
};

export default ErrorBoundary;
