import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthEnum } from "../../types/enum.types";

export interface AuthInterface {
  userId: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  accessTokenExpiration: string | null;
  referralCode: string | null;
}

const initialState: AuthInterface = {
  userId: localStorage.getItem(AuthEnum.userId) || null,
  accessToken: localStorage.getItem(AuthEnum.accessToken) || null,
  refreshToken: localStorage.getItem(AuthEnum.refreshToken) || null,
  accessTokenExpiration:
    localStorage.getItem(AuthEnum.accessTokenExpiration) || null,
  referralCode: localStorage.getItem(AuthEnum.referralCode) || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action: PayloadAction<AuthInterface>) {
      const { accessToken, accessTokenExpiration, userId, refreshToken } =
        action.payload;
      state.accessToken = accessToken;
      state.accessTokenExpiration = accessTokenExpiration;
      state.userId = userId;
      state.refreshToken = refreshToken;

      localStorage.setItem(AuthEnum.accessToken, accessToken);
      localStorage.setItem(AuthEnum.refreshToken, refreshToken);
      localStorage.setItem(AuthEnum.userId, userId);
      localStorage.setItem(
        AuthEnum.accessTokenExpiration,
        accessTokenExpiration
      );
    },
    getReferralCode(state, action: PayloadAction<string>) {
      const referralCode = action.payload;
      state.referralCode = referralCode;
      localStorage.setItem(AuthEnum.referralCode, referralCode);
    },
    logout(state) {
      localStorage.removeItem(AuthEnum.accessToken);
      localStorage.removeItem(AuthEnum.refreshToken);
      localStorage.removeItem(AuthEnum.userId);
      localStorage.removeItem(AuthEnum.accessTokenExpiration);

      // Use immer to safely update the state
      return {
        ...state,
        userId: null,
        accessToken: null,
        refreshToken: null,
        accessTokenExpiration: null,
      };
    },
  },
});

export const { login, logout ,getReferralCode} = authSlice.actions;
export default authSlice.reducer;
