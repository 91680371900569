import { JwtPayload, jwtDecode } from "jwt-decode";
import React from "react";
import { useSelector } from "react-redux";

const ArtistChecker = () => {
  interface CustomJwtPayload extends JwtPayload {
    roles?: string[];
  }

  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const decodedToken = accessToken
    ? (jwtDecode(`${accessToken}`) as CustomJwtPayload)
    : false;
    console.log("🚀 ~ ArtistChecker ~ decodedToken:", decodedToken)
  const isArtist = decodedToken
    ? decodedToken?.roles?.includes("artist")
    : false || false;
  console.log("🚀 ~ ArtistChecker ~ isArtist:", isArtist);

  return { isArtist };
};

export default ArtistChecker;
