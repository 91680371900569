import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import {store} from "./store";
import { BrowserRouter } from "react-router-dom";
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { polygon } from "viem/chains";
import ErrorBoundary from "./utils/handel-error";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const { publicClient, webSocketPublicClient } = configureChains(
  [polygon],
  [publicProvider()]
);

const config = createConfig({
  publicClient,
  webSocketPublicClient,
});
root.render(
  <ErrorBoundary>
    <WagmiConfig config={config}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </WagmiConfig>
  </ErrorBoundary>
);
// if (process.env.REACT_APP_ENVIRONMRNT == "production") {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }
