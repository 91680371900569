import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
const getAccessToken = (state) => {
  // Use state to access the access token from the Redux store
  return state.auth.accessToken;
};

export const Api = createApi({
  reducerPath: "public",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_MASTERPIEC_API }),
  refetchOnReconnect: true,
  tagTypes: [
    "explore",
    "SingleArt",
    "HomeApi",
    "exploreArtists",
    "exploreOwners",
  ],
  endpoints: (builder) => ({
    getArts: builder.query<any, any>({
      query: ({ pageNo, pageSize, filters }) => {
        const queryParams = [
          `pageNo=${pageNo}`,
          `pageSize=${pageSize}`,
          `sort=${filters.sortBy.value}`,
        ];

        if (filters?.onlySale === "true") {
          queryParams.push(
            `filters[type]=${filters?.fixedPrice == "true" ? "0" : ""}${
              filters?.fixedPrice == "true" && filters?.timedAuction == "true"
                ? ","
                : ""
            }${filters?.timedAuction == "true" ? "1" : ""}`
          );
        }

        if (filters.genre.length > 0) {
          queryParams.push(`filters[genre]=${filters.genre.join(",")}`);
        }

        const addDimensionFilters = (dimension, paramName) => {
          if (filters?.dimensions?.[dimension]?.length > 0) {
            queryParams.push(
              `filters[${paramName}]=${filters.dimensions[dimension][0]},${filters.dimensions[dimension][1]}`
            );
          }
        };

        addDimensionFilters("width", "width");
        addDimensionFilters("height", "height");
        addDimensionFilters("depth", "depth");
        addDimensionFilters("weight", "weight");

        if (filters?.price?.max) {
          queryParams.push(
            `filters[price]=${filters.price.min},${filters.price.max}`
          );
        }

        if (filters?.artist !== "all") {
          queryParams.push(`filters[artist]=${filters.artist.toLowerCase()}`);
        }

        if (filters?.owner !== "all") {
          queryParams.push(`filters[owner]=${filters.owner.toLowerCase()}`);
        }

        return `/arts:antique?${queryParams.join("&")}`;
      },
      providesTags: ["explore"],
    }),
    getArtists: builder.query<any, any>({
      query: ({ pageNo, pageSize, filters }) =>
        `/artists:antique?pageNo=${pageNo}&pageSize=${pageSize}${
          filters?.name ? `&filters[firstName]=${filters?.name}` : ""
        }`,
      providesTags: ["exploreArtists"],
    }),
    getOwners: builder.query<any, any>({
      query: ({ pageNo, pageSize, filters }) =>
        `/owners:antique?pageNo=${pageNo}&pageSize=${pageSize}${
          filters?.name ? `&filters[firstName]=${filters?.name}` : ""
        }`,
      providesTags: ["exploreOwners"],
    }),
    getHomeApi: builder.query<any, any>({
      query: () => `/home:antique`,
      providesTags: ["HomeApi"],
    }),
    getSingleArt: builder.query<any, any>({
      query: ({ artId }) => `/arts/${artId}`,
      providesTags: ["SingleArt"],
    }),
  }),
});
export const ProfileDetail = createApi({
  reducerPath: "UserProfile",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MASTERPIEC_API,
  }),
  refetchOnReconnect: true,
  tagTypes: ["profile", "profileArts"],
  endpoints: (builder) => ({
    getProfileDetail: builder.query({
      query: ({ identifier }) => `/profiles/identity/${identifier}`,
      providesTags: ["profile"],
    }),
    getProfileArts: builder.query({
      query: ({ publicKey, filters, pageNo, pageSize }) =>
        `/profile/${publicKey}/arts:art?pagination.pageNo=${pageNo}&pagination.pageSize=${pageSize}${
          filters === "true" ? `&pagination.filters[type]=[0]` : ""
        }`,
      providesTags: ["profileArts"],
    }),
  }),
});
export const ProtectAPIs = createApi({
  reducerPath: "ProtectAPIs",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MASTERPIEC_API,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getAccessToken(getState()); // Get the access token
      if (accessToken) {
        // Add the access token to the headers
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "getUserLocation",
    "paymentHistory",
    "tokenPaymentList",
    "paymentOrderInfo",
    "WishList",
  ],
  endpoints: (builder) => ({
    getUserLocation: builder.query({
      query: ({ perPage }) => `/profile/locations?pageSize=${perPage}`,
      providesTags: ["getUserLocation"],
    }),
    getPaymentHistory: builder.query({
      query: () => `/invoices?sort=-id&pageSize=50`,
      providesTags: ["paymentHistory"],
    }),
    getPaymentTokenList: builder.query({
      query: () => `/balance/payment/currencies`,
      providesTags: ["tokenPaymentList"],
    }),
    getOrderInfo: builder.query({
      query: ({ orderId }) => `/balance/payment/orders/${orderId}`,
      providesTags: ["paymentOrderInfo"],
    }),
    getWishList: builder.query({
      query: () => `/profile:antique/wishlist`,
      providesTags: ["WishList"],
    }),
  }),
});
export const nftAcount = createApi({
  reducerPath: "NFTs",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.rarible.org/v0.1/items/byOwner",
    prepareHeaders: (headers) => {
      // Set the X-API-KEY header
      headers.set("X-API-KEY", "a84bd16f-8e4f-4bad-a9ee-fa6a87053e81");
      return headers;
    },
  }),
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getNFTs: builder.query({
      query: ({ walletAddress }) =>
        `/?blockchains=POLYGON&owner=ETHEREUM:${walletAddress}&size=500`,
    }),
    // other API 2 endpoints...
  }),
});
export const SearchApi = createApi({
  reducerPath: "search",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_MASTERPIEC_API }),
  tagTypes: ["searchValue"],
  endpoints: (builder) => ({
    getSearch: builder.query<any, any>({
      query: ({ page, searchValue }) =>
        `/search:antique?q=${searchValue}&pageNo=${page}`,
      providesTags: ["searchValue"],
    }),
  }),
});

export const LocationApi = createApi({
  reducerPath: "Location",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_MASTERPIEC_API }),
  tagTypes: ["countries", "states", "cities"],
  endpoints: (builder) => ({
    getCountries: builder.query<any, any>({
      query: ({ pageSize, searchValue }) =>
        `/countries?pageSize=${pageSize}&${
          searchValue ? `filters[name]=${searchValue}` : ""
        }`,
      providesTags: ["countries"],
    }),
    getStates: builder.query<any, any>({
      query: ({ pageSize, searchValue, countryId }) =>
        `/states?pageSize=${pageSize}&${
          countryId ? `filters[countryId]=${countryId}` : ""
        }&${searchValue ? `filters[name]=${searchValue}` : ""}`,
      providesTags: ["states"],
    }),
    getCities: builder.query<any, any>({
      query: ({ pageSize, searchValue, stateId }) =>
        `/cities?pageSize=${pageSize}&${
          stateId ? `filters[stateId]=${stateId}` : ""
        }&${searchValue ? `filters[name]=${searchValue}` : ""}`,
      providesTags: ["cities"],
    }),
  }),
});

export const kucoinPriceCoinApi = createApi({
  reducerPath: "kucoin",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.kucoin.com",
    prepareHeaders: (headers) => {
      // Set the X-API-KEY header
      headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),

  tagTypes: ["prices"],
  endpoints: (builder) => ({
    getCoinPrices: builder.query<any, any>({
      query: () => `/api/v1/prices?currencies=MATIC,USDT,BTC,ETH`,

      providesTags: ["prices"],
    }),
  }),
});
export const {
  useGetArtsQuery,
  useGetSingleArtQuery,
  useGetArtistsQuery,
  useGetHomeApiQuery,
  useGetOwnersQuery,
} = Api;
export const { useGetNFTsQuery } = nftAcount;
export const { useGetSearchQuery } = SearchApi;
export const { useGetProfileDetailQuery, useGetProfileArtsQuery } =
  ProfileDetail;
export const {
  useGetPaymentHistoryQuery,
  useGetOrderInfoQuery,
  useGetPaymentTokenListQuery,
  useGetUserLocationQuery,
  useGetWishListQuery,
} = ProtectAPIs;
export const { useGetCitiesQuery, useGetCountriesQuery, useGetStatesQuery } =
  LocationApi;
export const { useGetCoinPricesQuery } = kucoinPriceCoinApi;
